import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const auth = useAuth();

  const isUserLogged = () => {
    return !!auth?.user?.session_state;
  };

  const checkIfAdvancedUser = () => {
    return !!auth?.user?.profile?.roles && (auth?.user?.profile?.roles.includes('analyst') ||
      auth?.user?.profile?.roles.includes('admin') ||
      auth?.user?.profile?.roles.includes('platform_admin')
    );
  };

  const checkIfAdminUser = () => {
    return !!auth?.user?.profile?.roles && (auth?.user?.profile?.roles.includes('admin') ||
      auth?.user?.profile?.roles.includes('platform_admin')
    );
  };

  return (
    <AuthContext.Provider value={{ auth, isUserLogged, checkIfAdvancedUser, checkIfAdminUser }}>
      { children }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any
};

export { AuthProvider, AuthContext };
